<template>
  <v-container fill-height>
    <div class="card-login">
      <div class="group-circle-1 bounce"></div>
      <div class="group-circle-2 bounce"></div>
      <div class="group-circle-3 bounce"></div>
      <div class="group-circle-4 bounce"></div>
      <div class="group-circle-5 bounce"></div>
      <div class="group-circle-6 bounce"></div>
      <div class="group-title-login">
        <div class="logo-login"></div>
        <!-- <h1 class="title-login">Legal Connect</h1> -->
        <!-- <div class="desc-login">Attorney At Law</div> -->
      </div>
      <div class="card-body">
        <ValidationObserver
          ref="observer"
          v-slot="{ valid }"
        >
          <v-card class="box-login">
            <v-card-text>
              <div class="title ml-11 mb-5">
                {{ title }}
              </div>
              <v-form>
                <v-col
                  cols="12"
                  class="mt-3"
                >
                  <render-input
                    :input="fieldConfig.userName"
                    v-model="credential.userName"
                    @keyup.enter="submit()"
                  ></render-input>
                </v-col>

                <v-col cols="12">
                  <render-input
                    :input="fieldConfig.password"
                    v-model="credential.password"
                    @keyup.enter="submit()"
                  ></render-input>
                </v-col>
              </v-form>
              <v-alert
                v-if="showError"
                type="error"
              >
                Invalid credential provided. Please verify your information and
                try again.
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="submit()"
                :disabled="!valid"
              >Login</v-btn>
            </v-card-actions>
            <!-- <v-card-actions class="mt-4" v-if="!isServerProduction">
              <v-spacer></v-spacer>
              <v-btn text small color="primary" @click="openForgotDialog">Forgot Passoword?</v-btn>
            </v-card-actions>-->
          </v-card>
        </ValidationObserver>
      </div>

      <!-- <custom-dialog
        ref="forgotDialog"
        size="small"
        title="Forgot your password?"
        @submit="submitForgotPassword"
      >
        <v-col>
          <render-input :input="fieldConfig.forgotData" v-model="forgotData"></render-input>
        </v-col>
      </custom-dialog>-->
    </div>
  </v-container>
</template>

<script>
import {
  logIn,
  logOut,
  getToken,
  setStorageToken,
} from "./../../js/authorization";
import { Roles, getRoles } from "./../../js/dropdown-service";
import { InputType } from "./../../js/constants";
import customDialog from "./../../components/custom/custom-dialog";
import { mapActions } from "vuex";
import { mockUpAdminUser } from "./../../_specific/exportJs";

export default {
  components: {
    customDialog,
  },
  data() {
    return {
      credential: {
        userName: "",
        password: "",
      },
      showError: false,
      forgotData: null,
    };
  },
  created() {
    this.hideLoading();
    logOut();
  },
  methods: {
    ...mapActions("loading", ["showLoading", "hideLoading"]),

    submit() {
      //fixme: mockup userName & password access to test mode
      let mockup = mockUpAdminUser(this.credential.userName);
      if (mockup.valid) {
        this.credential = mockup.credential;
        return;
      }

      this.showError = false;
      this.$refs.observer.validate().then((valid) => {
        if (!valid) {
          return;
        }
        this.showLoading();

        let payload = _.cloneDeep(this.credential);

        logIn(payload)
          .then((res) => {
            this.$router.push("/");
          })
          .catch((er) => {
            this.showError = true;
          })
          .finally(() => {
            this.hideLoading();
          });
      });
    },
    openForgotDialog() {
      this.$refs.forgotDialog.hideLoading();
      this.$refs.forgotDialog.showDialog();
    },
    submitForgotPassword() {
      this.showAlertSuccess(
        "Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder."
      );
      this.$refs.forgotDialog.hideDialog();
    },
  },

  computed: {
    title() {
      return `${process.env.VUE_APP_NAME} Login`;
    },
    fieldConfig() {
      return {
        userName: {
          name: "userName",
          text: "Username",
          type: InputType.text,
          icon: "person",
          rule: {
            required: true,
          },
        },
        password: {
          name: "password",
          text: "Password",
          type: InputType.password,
          icon: "lock",
          rule: {
            required: true,
          },
        },
        role: {
          name: "role",
          text: "Assume Role",
          type: InputType.dropdown,
          rule: {
            required: true,
          },
          data: {
            promise: getRoles(),
          },
        },
        testMode: {
          name: "testMode",
          text: "Test mode",
          type: InputType.checkBox,
        },
        forgotData: {
          name: "email",
          text: "Please Enter your email",
          type: InputType.text,
          rule: {
            required: true,
            //email: true,
          },
        },
      };
    },
  },
};
</script>
